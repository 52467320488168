import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./css/index.css";
import ErrorPage from "./pages/ErrorPage";
import Home from "./pages/Home";
import Integration from "./pages/Integration";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        {/* default home page */}
        <Route path="/" element={<Home />}></Route>

        {/* generic route - hopefully it would be never used */}
        <Route path="/oauth2/generic" element={<Integration integrationName="generic" timeout="500" />}></Route>

        {/* integration specific routes - add more below */}
        <Route path="/oauth2/microsoft/powerbi" element={<Integration integrationName="powerbi" timeout="0" />}></Route>
        <Route path="/oauth2/microsoft/sharepoint" element={<Integration integrationName="sharepoint" timeout="0" />}></Route>
        <Route path="/oauth2/servicenow" element={<Integration integrationName="servicenow" timeout="0" />}></Route>

        {/* error handling */}
        <Route path="*" element={<ErrorPage />}></Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
);
